.applyAndSaveButton{

    background-color: #F39C12;
    padding: 2%;
    padding-left: 4%;
    padding-right: 4%;
    outline: none;
    color: white;
    border: none;
    float:left;
}

.button-container{
    display: inline-block; /* Display the buttons in a row */
}

.buttonClass{
    padding: 1px 5px; /* Adjust the padding as needed */
    margin: 5px; /* Add some margin to separate the buttons */
    background-color: #F39C12; /* Change the background color */
    color: #fff; /* Change the text color */
    border: none;
    cursor: pointer;
}

.StockDetailsBackButton{

    background-color: #F39C12;
    /* width: 20%; */
    padding: 0.5%;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 2%;
    outline: none;
    color: white;
    border: none;
    float: left;
}

.chartDesign{
    background-color: white;
    border-radius: 5%;
    margin-top: 10%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 250px;
}

.transactionChartDesign{
    background-color: white;
    border-radius: 5%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 270px;
}

.transactionHistoryIcon{
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: block;
}

.transactionHistoryIconMobile{
    display: none;
}


@media only screen and (max-width:470px){ 

    .createCustomerTab{
        margin-top: 30%;
    }

    .chartDesign{
        height: 300px;
    }

    .transactionChartDesign{
       margin-top: 40px;
       height: 330px;
    }


    .transactionHistoryIcon{
        height: 50%;
        width: 50%;
        display: none;
    }

    .transactionHistoryIconMobile{
        height: 35%;
        width: 30%;
        cursor: pointer;
        display: block;
    }
}